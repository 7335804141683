<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-employee-left-panel :data-item='dataItem'></project-employee-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Temel Bilgiler</h3>

					<p class="text-lg">
                        Bu alanda personel temel bilgileri görüntülüyorsunuz. <br>
                        <br>
                        Personel bilgilerini güncellemek için güncelle butonuna tıklayın.
					</p>

				</Sidebar>

                <Button label='Güncelle' icon='pi pi-pencil' class='p-button-success mr-2 p-2 pl-3 pr-3' @click='editPers'/>

				</span>
            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <project-employee-tab-menu :employee-id='employeeId' :project-employee-id='projectEmployeeId'></project-employee-tab-menu>

                <div class='col-12'>
                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Adı</label>
                        <div class='col-12 md:col-10'>
                            {{ dataItem.name }}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Soyadı</label>
                        <div class='col-12 md:col-10'>
                            {{ dataItem.surName }}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>TC Kimlik Numarası</label>
                        <div class='col-12 md:col-10'>
                            {{ dataItem.identityNumber }}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Doğum Tarihi</label>
                        <div class='col-12 md:col-10'>
                            {{ formatDate(dataItem.birthday)}}
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Cinsiyet</label>
                        <div class='col-12 md:col-10'>
                           {{dataItem.gender}}
                        </div>
                    </div>

                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage,
    showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeService from '../../services/employeeService';
import moment from 'moment';
import ProjectEmployeeLeftPanel from './projectEmployeeLeftPanel';
import ProjectEmployeeTabMenu from './projectEmployeeTabMenu';
import { getEmployee, getProjectEmployee } from '../common/commonFunctions';
import { checkActiveProjectIdIsValid } from '../common/commonConstantFunctions';

export default {
    components: { ProjectEmployeeTabMenu, ProjectEmployeeLeftPanel, AdminToast },
    _employeeService: null,
    created() {
        this._employeeService = new EmployeeService();
        this.employeeId = this.$route.params.employeeId;
        this.projectEmployeeId = this.$route.params.projectEmployeeId;

    },
    async mounted() {
        this.dataItem = await getEmployee(this.employeeId);
        this.projectEmployee = await getProjectEmployee(this.projectEmployeeId);
        checkActiveProjectIdIsValid(this, this.projectEmployee.projectId);
    },
    data() {
        return {
            employeeId:0,
            projectEmployeeId:0,
            firmId: 0,
            dataItem: {
                name: '',
                isActive: true,
                email: '',
                phone: '',
                personName: '',
                personPhone: '',
                personEmail: '',
            },
            genders: [
                {
                    name: 'Erkek',
                    value: 'Erkek',
                },
                {
                    name: 'Kadın',
                    value: 'Kadın',
                },
            ],
            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        editPers () {
            this.$router.push({ name: 'editEmployee', params: { employeeId: this.employeeId } });
        },

        async save() {

            if (this.validateForm()) {
                const updateResponse = await this._employeeService.updateEmployee(this.employeeId, this.dataItem);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, 'Çalışan Güncelleme Başarılı');
                } else {
                    showErrorMessage(this, 'Çalışan Güncellemede Sorun İle Karşılaşıldı');
                }
            } else {
                showErrorMessage(this, 'Çalışan Güncellemede Sorun İle Karşılaşıldı');
            }

        },
        validateForm() {
            if (this.dataItem.name == null || this.dataItem.name == '') {
                showValidationMessage(this, 'Çalışan Adı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.surName == null || this.dataItem.surName == '') {
                showValidationMessage(this, 'Çalışan Soyadı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.identityNumber == null || this.dataItem.identityNumber == '') {
                showValidationMessage(this, 'Çalışan T.C. Kimlik No Alanı Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },

        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
    },
};

</script>


<style scoped>

</style>
